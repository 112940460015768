import { GenericLazyString } from '@eventbrite/i18n';
import { sanitizeHTML } from '../utils';

import {
    Divider,
    ExpansionPanel,
    ExpansionPanelContent,
    ExpansionPanelTrigger,
    Typography,
} from '@eventbrite/marmalade';
import { ResponsiveText } from '@eventbrite/wagtail-components';
import classNames from 'classnames';
import React, { useState } from 'react';
import { useTheme } from '../../../utils/context/theme-context';
import { VARIANTS } from '../../../utils/types';
import { BaseModuleProps } from '../types';
import './faq.scss';

const faqTitleContainerClasses = classNames(
    'l-align-left',
    'eds-g-cell-12-12',
    'faq-title',
);

interface Question {
    question: GenericLazyString;
    answer: string;
}

const QuestionComponent = ({
    variant,
    ...question
}: Question & BaseModuleProps) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const theme = useTheme();
    const baseOptions = { variant };
    const [expansionPanelContentClass] = theme.generateStyles(
        'expansion-panel-content',
        baseOptions,
    );
    const [expansionPanelHeadingClass] = theme.generateStyles(
        'expansion-panel-heading',
        baseOptions,
    );
    const [expansionPanelTriggerClass] = theme.generateStyles(
        'expansion-panel-trigger',
        baseOptions,
    );
    const [dividerClass] = theme.generateStyles('divider', baseOptions);
    return (
        <div>
            <ExpansionPanel
                variant="ghost"
                className={expansionPanelContentClass}
                onOpenChange={(open) => setIsExpanded(open)}
            >
                <ExpansionPanelTrigger className={expansionPanelTriggerClass}>
                    <div
                        className={`expansion-panel-heading ${expansionPanelHeadingClass}`}
                    >
                        <Typography as="h3" variant="body-lg" color={'none'}>
                            {question.question}
                        </Typography>
                    </div>
                </ExpansionPanelTrigger>
                <ExpansionPanelContent>
                    <ul className="expansion-panel-content">
                        <div
                            //eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{
                                __html: sanitizeHTML(question.answer),
                            }}
                        ></div>
                    </ul>
                </ExpansionPanelContent>
            </ExpansionPanel>
            {!isExpanded && <Divider className={dividerClass} />}
        </div>
    );
};

interface QuestionListProps {
    questions: Question[];
}
const QuestionList = ({
    questions,
    variant,
}: QuestionListProps & BaseModuleProps) => (
    <div className="questions-container">
        {questions.map((question, idx) => (
            <QuestionComponent key={idx} {...question} variant={variant} />
        ))}
    </div>
);

export interface FaqModuleProps {
    title?: string;
    questions: Question[];
    moduleClass: string;
    isContentFromContentful?: boolean;
}
const FAQModule = ({
    title,
    questions,
    moduleClass,
    isContentFromContentful,
    variant = VARIANTS.CORE_LIGHT,
}: FaqModuleProps & BaseModuleProps) => {
    const theme = useTheme();
    const baseOptions = { variant };
    const [backgroundClass] = theme.generateStyles(
        'background-v2',
        baseOptions,
    );
    const [textClass] = theme.generateStyles('text-v2', baseOptions);
    return (
        <section
            data-testid="faq-module"
            className={`mktg-top-p-100 eds-l-pad-hor-6 ${moduleClass} ${backgroundClass} `}
        >
            <div className="module-landing-page__module-container-content">
                <div className={faqTitleContainerClasses}>
                    {title && (
                        <>
                            {isContentFromContentful ? (
                                <ResponsiveText
                                    className={`faq-title ${textClass}`}
                                    base={{ fontSize: 52, lineHeight: 64 }}
                                    small={{ fontSize: 32, lineHeight: 40 }}
                                    // eslint-disable-next-line react/no-danger
                                    dangerouslySetInnerHTML={{
                                        __html: sanitizeHTML(title),
                                    }}
                                ></ResponsiveText>
                            ) : (
                                <ResponsiveText
                                    component="h2"
                                    base={{ fontSize: 52, lineHeight: 64 }}
                                    small={{ fontSize: 32, lineHeight: 40 }}
                                    className={`faq-title ${textClass}`}
                                >
                                    {title}
                                </ResponsiveText>
                            )}
                        </>
                    )}
                </div>
                <div>
                    <QuestionList questions={questions} variant={variant} />
                </div>
            </div>
        </section>
    );
};

export default FAQModule;
